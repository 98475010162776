function getDepartmentsButtons(departments) {
  return departments.map((department) => {
    const { id, name } = department
    return { type: "button", text: name, department: id }
  })
}

export function getQuestionnaireQuestions(departments?) {
  if (!departments || departments.length === 0) {
    return []
  }
  const external = [
    {
      type: "paragraph",
      text: "For questions about products, chat with a supplier:",
    },
  ].concat(getDepartmentsButtons(departments))
  const internal = [
    {
      type: "paragraph",
      text: "For help using the Parachute Health application, chat with us:",
    },
    { type: "button", text: "Parachute Health", department: "1" },
  ]
  return external.concat(internal)
}
